@import '../../index.scss';

.closed-menu {
    aside.panleft {
        transform: translateX(-200px);

        .panleft--logo {
            color: $secondary-color;
            font-size: 1px;
        }
    }

    .retractable-action-container {
        opacity: 1;
        z-index: 10;
        left: 190px;
        cursor: pointer;

        svg {
            border-radius: 0px 50% 50% 0;
            padding: 10px 15px;
            color: $secondary-color;
            font-size: 2rem;
            cursor: pointer;
            &:hover {
                background-color: $bg-color-dark;
            }
        }

        width: 50px;
        position: absolute;
        margin-right: 5px;
        top: 45%;
        align-self: flex-end;
        justify-self: center;
    }
}

aside.panleft {
    width: 99%;
    margin: 5px;
    height: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-area: panleft;
    transition: transform 1s;

    .panleft--logo {
        color: white;
        box-shadow: 2px 2px 4px 0px lightslategray;
        border-radius: 5px;
        background-color: $secondary-color;
        justify-content: center;
        align-items: center;
        height: 110px;
        width: 100%;
        font-size: 1.7rem;

        img {
            height: 75px;
            margin-right: 0.5em;
        }

        .brand-container {
            margin: 15px;
            text-decoration: none;
            color: white;
        }

        .main-menu-left--container {
            width: 100%;
        }

        .retractable-action-container {
            text-align: right;

            svg {
                &:hover {
                    color: $primary-color;
                }

                transition: all 0.2s;
                cursor: pointer;
            }
        }
    }

    .panleft--logo.full-height {
        height: 100%;
        align-items: center;
        justify-content: flex-start;
    }

    .footer {
        position: fixed;
        text-align: center;
        bottom: 1rem;
        left: 1.8rem;
        font-size: 0.7rem;
        color: $primary-color;
    }
}
