.button-component-container.pin {
    width: 20rem;
    height: 80px;
    margin: 1rem;
    h3 {
        font-size: 1.3rem;
    }
    svg {
        font-size: 3.5rem;
        vertical-align: 100%;
        top: -8px;
    }
}

.button-component-container {
    width: 25rem;
    height: 11rem;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 1px 1px 2px rgba(112, 128, 144, 0.644);
    color: rgba(255, 255, 255, 0.637);
    cursor: pointer;
    transition: all 0.2s;
    h3 {
        text-align: right;
        margin: 20px;
        z-index: 10;
        margin-right: 30px;
        font-size: 1.5rem;
        font-weight: 400;
    }
    svg {
        vertical-align: 25%;
        z-index: 0;
        position: relative;
        margin: -3rem 0 0 3rem;
    }
}

a.button-component-container {
    text-decoration: none;
    &:hover {
        box-shadow: 2px 2px 4px rgba(112, 128, 144, 0.644);
        color: white;
    }
    &:hover > svg {
        color: rgba(200, 200, 200, 0.616);
    }
}
