@import '../index.scss';

.projet-container {
    align-items: center;
    height: auto;
}

.projet-form {
    padding: 0;
    width: 80%;

    form {
        padding: 0 !important;
        padding-bottom: 2rem !important;
    }
}

#new-projet-form {
    display: block;
    position: relative;
    left: 0;

    .flex-container.column-container {
        .neo-card {
            margin-left: 5px;
            max-width: 80%;

            #random-color-dice {
                font-size: 2rem;
                align-self: center;
                margin: 0 0.5rem;
                transition: all 0.2s;

                &:hover {
                    cursor: pointer;
                    filter: hue-rotate(15deg);
                    font-size: 2.2rem;
                }
            }
        }
    }
}

form {
    fieldset {
        border: none;

        input,
        select {
            font-size: 1rem;
        }

        :disabled {
            opacity: 1;
            font-size: 1rem;
            color: $bg-color-dark !important;
            background-color: $bg-color !important;
            border: 1px solid $bg-color !important;
        }
    }
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    // .submit-container {
    //   width: 20rem;
    //   position: fixed;
    //   left: 45vw;
    //   top: 25vh;
    // }

    .neo-card {
        .criticite-display-field {
            width: 100%;
            text-align: left;
            color: $secondary-color;
            background-color: $bg-color;
            border-radius: 5px;
            margin: 2px;
            padding: 2px 5px;
            text-indent: 10px;
            cursor: default;
        }

        .criticite-field-container {
            align-items: center;
            transition: all 0.2s;
            border-radius: 5px;
            background-color: $bg-color;
            flex-wrap: nowrap;
            margin: 5px 0;
            width: 100%;
            padding: 2px;
            justify-content: space-between;

            .flex-container {
                align-items: center;
            }

            label {
                font-size: 0.9rem;
                cursor: pointer;
                color: $secondary-color--light;
            }

            input[type='number'] {
                background-color: white;
                width: 5ch;
                height: 2ch;
            }

            input {
                margin: 0 5px;
            }

            &:hover {
                label {
                    color: $secondary-color;
                }
            }
        }

        .disabled-contact {
            .contact-card {
                &:hover {
                    pointer-events: none;
                }
            }
        }

        .contact-card {
            background-color: $bg-color;
            border-radius: 10px;
            padding: 5px;
            width: 12em;
            margin: 5px;
            overflow: hidden;
            height: 6em;
            cursor: pointer;
            border: 1px solid grey;
            color: rgb(54, 40, 35);
            transition: all 0.2s;

            &:hover {
                border-color: $primary-color;

                h4 {
                    color: $primary-color;
                }
            }

            b {
                font-size: 0.9rem;
                margin: 0 5px;
            }

            h4 {
                font-size: 1.1rem;
                color: grey;
                margin: 5px;
                text-align: center;
            }

            p {
                font-size: 0.8rem;
            }
        }

        .contact-card.checked-true {
            border-color: lighten($valid-color, 30%);

            h4 {
                color: lighten($valid-color, 30%);
            }

            &:hover {
                border-color: $primary-color--dark;

                h4 {
                    color: $primary-color--dark;
                }
            }
        }

        .sub-card-container {
            max-width: 100%;
            overflow: hidden;
        }

        .fadeIn {
            animation: fadeIn 1.4s;
        }

        .reaction-img {
            height: 175px;
        }

        margin: 1rem;

        h3 {
            text-align: left;
            align-self: flex-start;
            font-weight: 600;
            margin-bottom: 0;
            text-indent: 1rem;
        }

        p.button-container {
            a {
                color: rgb(196, 196, 196);
            }

            a:hover {
                color: white;
            }
        }

        p {
            text-align: left;
            align-self: flex-start;
            margin: 0.4rem 0;

            strong {
                color: $primary-color;
                font-weight: 600;
                letter-spacing: 1px;
                margin-right: 0.5rem;
            }

            .button {
                background-color: $primary-color;
                padding: 5px;
                text-decoration: none;
                color: white;
                border-radius: 5px;

                &:hover {
                    color: white;

                    svg {
                        color: white;
                    }
                }
            }
        }

        .checkbox-row {
            label {
                width: 70%;
                max-width: 100%;
            }

            div {
                width: 30%;
            }
        }

        legend {
            text-indent: 1rem;
            max-width: 100%;
            text-align: justify;
        }

        fieldset {
            margin-top: 1rem;
            padding: 0;
            width: 95%;

            h4 {
                font-size: 1rem;
                font-weight: normal;
                margin: 5px 0;
                color: $secondary-color;
            }
        }

        div {
            width: 100%;

            input[type='date'] {
                width: calc(100% + 0.5rem);
            }

            select {
                width: calc(100% + 0.5rem);
                max-width: 102%;
                border-radius: 5px;
                border: 1px solid #b6b6b6;
                margin: 5px;
            }
        }

        .submit-button {
            max-width: 50%;
            align-self: center;
        }

        input,
        textarea,
        select {
            max-width: 100%;
            width: 100%;
        }
    }
}
