/* toggle.css */

button {
    border: none;
}

.toggle {
    background-color: #b7b9ba;
    border: 1px solid #aaa;
    border-radius: 99px;
    width: 40px;
    height: 22px;
    transition: background-color 0.1s ease, border-color 0.2s ease;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
    position: relative;
}

.toggle .thumb {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 99px;
    transform: translateX(0);
    transition: left 0.15s ease;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
}

.toggle.active {
    background-color: #4caf50;
    border-color: #4caf50;
}

.toggle.active .thumb {
    left: calc(100% - 19px);
}

/* Responsive styles */
@media (max-width: 1200px) {
    .toggle {
        width: 28px;
        height: 15px;
    }

    .toggle .thumb {
        height: 11px;
        width: 11px;
        left: 2px;
    }

    .toggle.active .thumb {
        left: calc(100% - 11px);
    }
}

@media (min-width: 1221px) and (max-width: 1520px) {
    .toggle {
        width: 33px;
        height: 17px;
    }

    .toggle .thumb {
        height: 14px;
        width: 14px;
        left: 2px;
    }

    .toggle.active .thumb {
        left: calc(100% - 16px);
    }
}

@media (min-width: 1521px) and (max-width: 1750px) {
    .toggle {
        width: 35px;
        height: 20px;
    }

    .toggle .thumb {
        height: 16px;
        width: 16px;
        left: 3px;
    }

    .toggle.active .thumb {
        left: calc(100% - 19px);
    }
}
