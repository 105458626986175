@import '../../index.scss'; /* GESTION CASE OUTOFINTERVAL */

.icon-right-panel {
    transition: all 0.2s;
    color: $secondary-color;

    svg {
        font-size: 1.4rem;
    }
    &:hover {
        color: $primary-color;
    }

    .right {
        text-align: right;
    }
}

.testSide {
    display: inline-block;
    margin-right: 50px;
    vertical-align: middle;
    .icons {
        margin-left: 20%;
    }
}

div.day-container:not(.out-of-range) > .calendar--bottom-container {
    cursor: pointer;
}
.global-date-container {
    .date-calendar-container {
        margin-left: 30%;
    }
    .user-input {
        width: 30%;
        margin-left: auto;
    }
}

.times-container {
    display: flex;
    height: auto;
    align-items: stretch;
    justify-content: center;
}
@keyframes slideIn {
    0% {
        width: 0;
        opacity: 0;
        transform: translateX(300px);
    }

    100% {
        width: 300px;
        opacity: 1;
        transform: translateX(0);
    }
}

.right-panel-container.neo-card {
    display: none;
    flex-grow: 0;
    justify-content: flex-start;

    .communication-container {
        width: 100%;

        img {
            width: 50px;
        }
    }
}

.right-panel-container.neo-card.displayed {
    $width: 300px;
    display: flex;
    width: $width;
    padding: 0;
    border-radius: 5px;
    background-color: $bg-color-light;
    box-shadow: 2px 2px 4px lightslategray;
    flex-grow: 1;
    animation: slideIn 0.5s;

    // input,
    // label:not(.exclureLabel),
    // option,
    // select,
    // textarea {
    //     width: 98%;
    //     max-width: none !important;
    //     padding: 5px !important;
    //     // overflow: hidden !important;
    // }

    .inputTimeActivity {
        width: 98%;
    }

    // textarea {
    //     height: 10rem;
    // }

    .panel-icon-container {
        display: flex;
        margin: 5px 10px 0;
        color: $secondary-color;
        justify-content: flex-end;
        transition: all 0.2s;
        cursor: pointer;

        svg {
            font-size: 1.4rem;
        }

        &:hover {
            color: $primary-color;
        }
    }

    .go-back-icon-container {
        // position: fixed;
        height: 2.4rem;
        margin: 0;
        align-items: center;
        justify-content: flex-start;
    }

    .close-icon-container {
        align-self: flex-end;
        justify-content: flex-end;
    }
}
/* STYLE GLOBAL CALENDRIER */

.calendar-container {
    height: auto;
    padding: 0 0.5rem;
    flex-grow: 8;
    transition: all 0.5s;

    .calendar--top-panel {
        width: 100%;
        padding: 0.3rem 0;
        color: white;
        border-radius: 5px;
        background-color: $primary-color;
        box-shadow: 2px 2px 3px lightslategray;
        font-size: 1.2rem;
        justify-content: space-between;

        span {
            .selector-container {
                position: relative;
                padding: 0 0.2rem;
            }

            // select {
            //     -moz-appearance: none;
            //     -webkit-appearance: none;
            //     width: auto;
            //     min-width: 0;
            //     margin: 0;
            //     padding: 0;
            //     color: white;
            //     border: none;
            //     border-bottom: 1px dashed white;
            //     background-color: transparent;
            //     text-align: center;
            //     text-indent: 1px;
            //     text-overflow: "";
            //     font-size: 1.2rem;
            //     transition: all 0.2s;
            //
            //     &:active,
            //     &:focus,
            //     &:hover {
            //         color: $secondary-color;
            //         border-bottom: 1px dashed $secondary-color;
            //     }
            //
            //     option:hover {
            //         color: white;
            //         background-color: $primary-color;
            //     }
            // }
        }

        .month-changer-chevron {
            padding: 5px 15px;
            font-size: 1.2rem;
            transition: all 0.2s;
            cursor: pointer;

            &:hover {
                color: $secondary-color;
            }
        }
    }

    .calendar-days-container {
        margin: 0.3rem 0.2rem 0 0.15rem;
        border-radius: 5px;
        box-shadow: 2px 2px 3px lightslategray;

        table,
        td,
        th {
            margin: 0;
            padding: 0;
            border: 1px solid #00000001;
            border-collapse: collapse;
            text-align: center;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
                Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
                sans-serif;
        }

        table {
            width: 100%;

            tr {
                display: flex;
                flex-direction: row;
            }

            td {
                display: flex;
                width: 12%;
                flex-direction: column;
                flex-grow: 1;
                justify-content: flex-start;

                .day-container {
                    position: relative;
                }

                &:active,
                &:focus,
                &:hover {
                    .day-container .calendar--bottom-container {
                        background-color: rgba($primary-color, 0.1);
                    }
                }

                div {
                    width: 100%;
                    height: 100%;
                }

                .infoBulle {
                    text-align: left;
                    width: auto;
                    height: auto;

                    .multi-line {
                        text-align: left;
                    }

                    &.infoBulle-overflow-information {
                        background-color: lighten($primary-color, 8%);
                    }
                }
            }

            td .out-of-interval {
                .calendar--upper-container {
                    color: $bg-color;
                    background-color: lighten($secondary-color, 10%);
                }

                .calendar--bottom-container {
                    background-color: #dedede;
                }
            }

            td.week-counter {
                width: 4rem;
                align-items: center;
                flex-grow: 0;

                .calendar--upper-container {
                    background-color: lighten($secondary-color, 10%);
                }

                .calendar--bottom-container {
                    padding: 5px;
                    color: lightslategray;
                    font-size: 0.8rem;

                    svg {
                        padding: 1rem 0;
                        color: lighten($valid-color, 20%);
                        font-size: 1.3rem;
                    }
                }
            }

            .calendar--upper-container {
                display: flex;
                width: 100%;
                height: 0.9rem;
                padding: 0.4rem 0.1rem;
                color: $bg-color-light;
                background-color: $primary-color;
                box-shadow: 2px 0 2px rgba(0, 0, 0, 0.541);
                align-items: center;
                justify-content: center;
                cursor: default;
            }

            .calendar--bottom-container {
                width: 100%;
                height: 100%;
                height: 11vh !important;
                background-color: $bg-color-light;
                flex-grow: 1;

                .day-number {
                    margin: 1px 0 0 5px;
                    color: lighten($secondary-color, 10%);
                    align-self: flex-start;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
                        'Helvetica Neue', sans-serif;
                    font-size: 0.8rem;

                    .ticket-container {
                        margin-left: 0.5rem;
                        color: $secondary-color;
                        font-weight: 600;
                    }
                }

                .activities-container {
                    align-items: flex-start;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .overflow-information {
                        position: absolute;
                        bottom: -2px;
                        right: 0px;
                        text-align: center;
                        padding: 4px 6px;
                        border-top-left-radius: 3px;
                        color: $bg-color;
                        background-color: lighten($primary-color, 8%);
                    }
                }

                .activity-pin {
                    width: 93%;
                    margin: auto 4px;
                    border-left: 4px solid;
                    background-color: rgba(0, 0, 0, 0.05);
                    align-self: flex-start;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;

                    i {
                        position: relative;
                        top: 0;
                        left: 0;
                        margin-right: 0;
                        margin-left: 0.2rem;
                        align-self: flex-start;
                        font-size: 0.6rem;
                    }

                    span {
                        margin: auto;

                        svg {
                            margin-left: 0.2rem;
                            color: lighten($secondary-color, 20%);
                            font-size: 0.9rem;
                        }
                    }
                }

                .activity-pin.activity-pin--halved {
                    width: 44.5%;
                    margin-right: 0;
                    font-size: 0.8rem;

                    span {
                        margin-left: calc(20% - 0.1rem);
                    }

                    i {
                        font-size: 0.5rem;
                    }
                }
            }
        }
    }
}

//
//
//
//
//
//
//
//
//
//
//
.horizontal {
    display: flex;
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden;
    align-items: stretch;
    justify-content: center;

    .calendar--top-panel {
        width: 99%;
    }

    // .calendar-container {
    //     width: 100%;
    // }

    .horizontal-calendar-days-container {
        display: block;
        width: 100%;
        overflow: hidden;

        table {
            display: flex;
            width: 99%;
            overflow: auto;
            border-collapse: collapse;
            flex-direction: column;

            tbody {
                .out-of-interval {
                    color: #616161;
                    background-color: #dedede;
                }

                .semaine-number {
                    padding: 1px 0;
                    color: white;
                    border-color: transparent;
                    background-color: $primary-color;
                    text-align: center;
                    font-size: 0.7vw;
                }

                tr:first-of-type {
                    position: sticky;

                    .person-th {
                        border-left: none;
                        background-color: transparent;
                    }
                }

                .employe-row {
                    height: 3vw;
                    transition: all 0.2s ease-in;
                }

                tr {
                    display: flex;
                    width: 100%;
                    min-height: 2.5vw;
                    margin: 2px 0;
                    flex-direction: row;
                }

                td {
                    margin: 0;
                    overflow: hidden;
                    border: 1px solid lighten($secondary-color, 60%);
                    border-top: none;
                    border-left: none;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-wrap: anywhere;

                    .activities-container {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 0.8rem;
                        font-weight: 400;
                        white-space: nowrap;

                        .activity-pin-macro {
                            width: 90%;
                            overflow: hidden;
                            border-left: 3px solid;
                            outline: 1px solid white;
                            text-align: center;
                            text-overflow: ellipsis;
                            align-self: center;
                            font-size: 0.7vw;
                            white-space: nowrap;

                            svg {
                                margin-left: 0.2rem;
                                color: lighten($secondary-color, 20%);
                                font-size: 0.65rem;
                            }
                        }

                        .activity-pin-macro.halved {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 0.5vw;
                            white-space: nowrap;
                        }
                    }
                }

                .day-container {
                    display: flex;
                    width: 0;
                    border-right: 1px solid lighten($secondary-color, 60%);
                    text-align: center;
                    align-items: center;
                    font-size: 0.6rem;
                    font-weight: lighter;
                    flex: 1;
                    flex-direction: column;
                    cursor: default;

                    .ticket-container {
                        position: fixed;
                        margin-top: 0.2em;
                        margin-left: 0.9vw;
                        color: $secondary-color;
                        font-size: 0.4vw;
                        font-weight: 600;
                    }
                }

                .horizontal-day-title-container {
                    font-size: 0.5vw;
                    flex: 1;

                    span {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        word-wrap: anywhere;
                    }
                }

                .day-container:last-child,
                .day-title-container:last-child {
                    border-right: none;
                }

                .day-container.false {
                    background-color: #dedede;
                }

                .day-container:not(.horizontal-day-title-container) {
                    &:hover {
                        background-color: rgba($primary-color, 0.1);
                        cursor: pointer;
                    }
                }

                .person-th {
                    width: 4em;
                    max-width: 60px;
                    color: white;
                    border-color: transparent;
                    background-color: $primary-color;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    cursor: default;

                    b {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

.activities-container {
    height: 10.6vh !important;
    overflow: hidden;
    // pointer-events: none;

    i {
        display: flex;
        align-self: flex-start;
        font-size: 0.8rem;
        justify-content: center;
    }
}

.isSelected {
    background-color: lighten($valid-color, 20%);
    &:hover {
        background-color: lighten($valid-color, 40%);
    }
}

.isNotSelected {
    background-color: lighten($danger-color, 20%);
    &:hover {
        background-color: lighten($danger-color, 40%);
    }
}
