$bg-color: #f3f5f9;
$bg-color-light: #ffff;
$bg-color-dark: #292b33;
$primary-color: #ff5c40;
$primary-color--dark: darken($primary-color, 40%);
$secondary-color: #2a3237;
$secondary-color--light: #505050;
$valid-color: #2b6955;
$danger-color: #ee2929;

:root {
    --toastify-color-dark: #292b33;
    --toastify-color-info: #4d5c65;
    --toastify-color-success: #49b190;
    --toastify-color-warning: #ff5c40;
    --toastify-color-error: #fe2500;
}

.primary {
    color: $primary-color;
}

.primary-color--dark {
    color: $primary-color--dark;
}

.bg-color {
    color: $bg-color;
}

.secondary-color--light {
    color: $secondary-color--light;
}

.bg-secondary-color--light {
    background-color: $secondary-color--light;
}

.bg-color--light {
    background-color: $bg-color-light;
}

.bg-primary {
    background-color: $primary-color !important;
}

.alert-danger,
.error-text {
    color: $primary-color--dark;
}

.bg-alert-danger {
    background-color: $primary-color--dark !important;
}

.bg-color-dark {
    background-color: $bg-color-dark;
}

.bg-secondary {
    background-color: $secondary-color !important;
}

.bg-valid {
    background-color: $valid-color !important;
}

.error-text {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    text-align: center;
}

html {
    width: 100%;
    height: 100vh;
}

body {
    margin: 0;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $bg-color;
}

/** Global app grid **/
#root {
    height: 100%;
    width: 100%;
}

div.global-grid-container {
    min-height: 25rem;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: [left] 15rem auto 0 [right];
    grid-template-rows: [top] 140px auto [bottom];
    grid-template-areas:
        'panleft head head'
        'panleft mainbody mainbody';
    transition: grid-template-columns 1s;
}

div.global-grid-container.closed-menu {
    grid-template-columns: [left] 2rem auto 0 [right];
}

/** Global assets */
.flex-container {
    display: flex;
}

legend {
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    max-width: 85%;
    text-align: left;
    color: #505050;
    font-size: 0.8rem;
}

.row-container {
    flex-direction: row;
}

.column-container {
    flex-direction: column;
}

.flex-start {
    justify-content: flex-start;
    align-self: flex-start;
}

.flex-end {
    justify-content: flex-end;
    align-self: flex-end;
}

.padded-container {
    width: 92%;
    padding: 2rem 4%;
}

.global-button {
    border-radius: 5px;
    padding: 0.4rem 0.9rem;
    font-size: 0.8rem;
    transition: 0.2s;
    border: 0px solid transparent;

    &:hover {
        box-shadow: 0 0 1px lightslategray;
        color: $bg-color;
    }
}

form {
    fieldset {
        label {
            text-align: justify;
        }

        label.sub-label {
            text-indent: 0.5rem;
            color: $secondary-color;
            margin-top: 0;
            font-size: 0.6rem;
        }
    }
}

//Définition du sélecteur principal btn
.btn {
    color: white;
    border-radius: 5px;
    // box-shadow: 1px 1px 3px lightslategray;
    border: none;

    //Définition des sélecteurs supplémentaires gérant les couleurs
    &.btn-primary {
        background-color: $primary-color;

        &:hover {
            color: white;
            background-color: lighten($primary-color, 5%);
        }
    }

    &.btn-secondary {
        background-color: $secondary-color;

        &:hover {
            color: $primary-color;
            background-color: darken($secondary-color, 5%);
        }
    }

    //Définition des ssélecteurs susupplémentaires gérant les tailles
    //small
    &.btn-sm {
        width: 2%;
        height: 2%;
    }
    //medium
    &.btn-me {
        width: 4%;
        height: 4%;
    }
    //large
    &.btn-la {
        width: 6%;
        height: 6%;
    }
}

.p-1 {
    padding: 0.3%;
}

.p-2 {
    padding: 0.6%;
}

.p-3 {
    padding: 1%;
}

.modify-button-container,
.modify-button-container.button-container {
    position: fixed;
    right: 2rem;
    bottom: 0.5rem;

    &:hover {
        .button {
            svg {
                color: white;
            }
        }
    }

    * {
        font-size: 1rem;
        padding: 0.6rem;
    }
}

.aligned-container {
    justify-content: center;
    align-items: center;
}

.aligned-top-container {
    justify-content: flex-start;
    align-items: center;
}

b.link,
a {
    cursor: pointer;
    font-weight: normal;
    color: $primary-color;
    transition: 0.3s;

    &:hover {
        color: $valid-color;
    }
}

.small-form-aligned-top {
    margin-top: 5rem;
    align-self: flex-start;

    .card-container {
        .neo-card {
            width: 450px;

            fieldset.button-container {
                justify-content: center;
                align-items: center;
                width: 100%;
                flex-direction: row;

                button {
                    justify-content: center;
                    width: 40%;
                    align-items: center;

                    &:hover {
                        svg {
                            color: white;
                        }
                    }
                }

                button:last-of-type {
                    margin-left: 10px;
                }
            }
        }
    }
}

.loader {
    margin: 100px auto;
    text-align: center;
    align-self: center;
    font-size: 25px;
    width: 1em !important;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.neo-card {
    border-radius: 10px;
    box-shadow: 2px 2px 4px lightslategray;
    background-color: white;
    padding: 1rem 2rem;

    h2 {
        margin: 0.5rem 1rem;
        font-weight: lighter;
        text-align: center;
        color: $primary-color;
    }

    h3 {
        margin: 0.5rem 1rem;
        font-weight: 400;
        color: $primary-color;
    }

    fieldset {
        .loader {
            font-size: 1rem;
            margin: 2rem auto;
            width: 1rem;
            height: 1rem;
        }
    }

    .shine {
        animation: shine 2s infinite;

        &:hover {
            animation: none;
        }
    }

    @keyframes shine {
        0% {
            filter: brightness(100%);
        }

        50% {
            filter: brightness(160%);
        }

        100% {
            filter: brightness(100%);
        }
    }

    .loader {
        margin: 100px auto;
        font-size: 25px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        position: relative;
        text-indent: -9999em;
        -webkit-animation: load5 1.1s infinite ease;
        animation: load5 1.1s infinite ease;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    @-webkit-keyframes load5 {
        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #ff8000, 1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2),
                2.5em 0em 0 0em rgba(255, 128, 0, 0.2), 1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2),
                0em 2.5em 0 0em rgba(255, 128, 0, 0.2), -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2),
                -2.6em 0em 0 0em rgba(255, 128, 0, 0.5), -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.7);
        }

        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.7), 1.8em -1.8em 0 0em #ff8000,
                2.5em 0em 0 0em rgba(255, 128, 0, 0.2), 1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2),
                0em 2.5em 0 0em rgba(255, 128, 0, 0.2), -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2),
                -2.6em 0em 0 0em rgba(255, 128, 0, 0.2), -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.5);
        }

        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.5),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.7), 2.5em 0em 0 0em #ff8000,
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.5), 2.5em 0em 0 0em rgba(255, 128, 0, 0.7),
                1.75em 1.75em 0 0em #ff8000, 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.5),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.7), 0em 2.5em 0 0em #ff8000,
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.5), 0em 2.5em 0 0em rgba(255, 128, 0, 0.7),
                -1.8em 1.8em 0 0em #ff8000, -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.5),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.7), -2.6em 0em 0 0em #ff8000,
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.5), -2.6em 0em 0 0em rgba(255, 128, 0, 0.7),
                -1.8em -1.8em 0 0em #ff8000;
        }
    }

    @keyframes load5 {
        0%,
        100% {
            box-shadow: 0em -2.6em 0em 0em #ff8000, 1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2),
                2.5em 0em 0 0em rgba(255, 128, 0, 0.2), 1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2),
                0em 2.5em 0 0em rgba(255, 128, 0, 0.2), -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2),
                -2.6em 0em 0 0em rgba(255, 128, 0, 0.5), -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.7);
        }

        12.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.7), 1.8em -1.8em 0 0em #ff8000,
                2.5em 0em 0 0em rgba(255, 128, 0, 0.2), 1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2),
                0em 2.5em 0 0em rgba(255, 128, 0, 0.2), -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2),
                -2.6em 0em 0 0em rgba(255, 128, 0, 0.2), -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.5);
        }

        25% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.5),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.7), 2.5em 0em 0 0em #ff8000,
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        37.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.5), 2.5em 0em 0 0em rgba(255, 128, 0, 0.7),
                1.75em 1.75em 0 0em #ff8000, 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        50% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.5),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.7), 0em 2.5em 0 0em #ff8000,
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.2), -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        62.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.5), 0em 2.5em 0 0em rgba(255, 128, 0, 0.7),
                -1.8em 1.8em 0 0em #ff8000, -2.6em 0em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        75% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.5),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.7), -2.6em 0em 0 0em #ff8000,
                -1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2);
        }

        87.5% {
            box-shadow: 0em -2.6em 0em 0em rgba(255, 128, 0, 0.2),
                1.8em -1.8em 0 0em rgba(255, 128, 0, 0.2), 2.5em 0em 0 0em rgba(255, 128, 0, 0.2),
                1.75em 1.75em 0 0em rgba(255, 128, 0, 0.2), 0em 2.5em 0 0em rgba(255, 128, 0, 0.2),
                -1.8em 1.8em 0 0em rgba(255, 128, 0, 0.5), -2.6em 0em 0 0em rgba(255, 128, 0, 0.7),
                -1.8em -1.8em 0 0em #ff8000;
        }
    }

    fieldset {
        min-width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // border: none;

        input,
        textarea,
        select {
            margin: 5px 0;
            padding: 5px;
            max-width: 80%;
            border: 1px solid transparent;
            border-radius: 5px;
            transition: all 0.2s;

            &:active,
            &:focus,
            &:hover {
                border-color: $primary-color !important;

                * {
                    border: 1px solid $primary-color !important;
                    background-color: transparent !important;
                }
            }
        }

        option:selected {
            color: $primary-color;
        }

        input[type='date'] {
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
                'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            font-size: 0.9rem;
        }

        div.flex-container {
            label {
                max-width: 50%;
            }

            flex-wrap: wrap;
        }

        // input[type="checkbox"] {
        //   width: 2rem;
        //   height: 2rem;
        //   padding: 2rem;
        // }

        button {
            width: 15rem;
            padding: 0.8rem;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 1.1rem;
            background-color: $primary-color;
            box-shadow: 1px 1px 3px lightslategray;

            &:hover {
                background-color: lighten($primary-color, 5%);
                box-shadow: 1px 2px 3px lightslategray;
            }
        }

        button.delete-button {
            margin: 5px;
            font-size: 0.95rem;
            align-self: center;
            width: 12rem;
            color: darken(white, 10%);
            background-color: $primary-color--dark;

            &:hover {
                svg {
                    color: white;
                }

                background-color: lighten($primary-color--dark, 10%);
                color: white;
            }
        }

        form {
            flex-wrap: wrap;
            max-width: 45rem;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .alert.alert-danger {
            color: rgb(248, 109, 109);
            text-indent: 0.5rem;
            font-size: 0.9rem;
            animation: fadeIn 0.3s;
        }

        .fadeIn {
            animation: fadeIn 0.3s;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        legend {
            // max-width: 85%;
            text-align: left;
            color: $secondary-color--light;
            font-size: 0.8rem;
        }

        label {
            font-size: 1rem;
            margin: 5px 0;
            color: $secondary-color;
        }
    }

    //dslkjdslkfjdslfkjdsflj
}

.body-main-container {
    grid-area: mainbody;
    padding: 5px;
    overflow-x: hidden;
}

//disabling button
.disabled-button {
    cursor: not-allowed !important;
    filter: grayscale(80%);
    color: grey;

    &:active {
        animation: nope 0.2s;
    }
}

.disabled-content {
    cursor: not-allowed !important;
    color: grey !important;

    &:hover {
        color: grey !important;
    }

    &:active {
        animation: nope 0.2s;
    }
}

div.button,
a.button,
button {
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        color: $primary-color;

        svg {
            transition: all 0.2s;
            color: $primary-color;
        }
    }
}

// Le drawer qui empeche de cliquer sur l'élément gravitee-connexion au format mobile
// on le rend inactif pour qu'il n'altère pas les comportements de clique sur les élémnents enfants
.css-wb8opk-MuiModal-root-MuiDrawer-root {
    pointer-events: none;
}

@keyframes searchSlideIn {
    0% {
        z-index: -1;
        transform: translateX(-5rem);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        z-index: inherit;
    }
}

@keyframes searchSlideOut {
    0% {
        opacity: 1;
        width: 13rem;
        z-index: -1;
        transform: translateX(0);
    }

    50% {
        opacity: 1;
        transform: translateX(-5rem);
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

div.button-container {
    margin: 10px 0;
    display: flex;
    flex-direction: row;

    .search-bar-container.hidden-container {
        opacity: 0;
        z-index: -1;
        animation: searchSlideOut 0.2s;
        width: 0;
    }

    .search-bar-container {
        animation: searchSlideIn 0.2s;
        width: 13rem;
        border-radius: 0 5px 5px 0;
        margin-left: -10px;
        margin-right: 10px;
        height: 1.5rem;
        transition: all 0.2s;
        box-shadow: 1px 1px 1px rgba(112, 128, 144, 0.705);
        border-left: none;

        &:focus-within {
            background-color: white;
            width: 13.6rem;
            box-shadow: 2px 2px 2px rgba(112, 128, 144, 0.805);
            font-size: 1rem;

            input {
                color: black;
            }
        }

        input {
            margin-left: 15px;
            border: none;
            background-color: transparent;
            width: 90%;
            padding: 0;

            &:focus,
            &:focus-visible {
                border: none;
                outline: none;
            }
        }

        svg {
            transition: 0.2s;
            cursor: pointer;
            margin: 2px 5px;
            font-size: 0.8rem;
            align-self: flex-start;

            &:hover {
                color: $primary-color;
            }
        }
    }

    a,
    span {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: auto;
        min-width: 10rem;
        margin-right: 5px;
        background: $primary-color;
        font-size: 0.8rem;
        text-align: center;
        transition: 0.2s;
        border-radius: 5px;
        height: 1.5rem;
        box-shadow: 1px 1px 1px slategray;
        color: lighten($secondary-color--light, 55%);

        label {
            cursor: pointer;
            margin: 0 15px;
        }

        &:hover {
            color: white;
            box-shadow: 2px 2px 3px rgb(255, 255, 255);
        }
    }
}

@keyframes nope {
    50% {
        transform: translateX(-3px);
    }

    100% {
        transform: translateX(0px);
    }
}

//disabling links
a.disabled-link {
    cursor: default;
    color: grey !important;
    pointer-events: none;

    &:hover {
        color: darken($primary-color, 30);
    }

    &:active {
        animation: nope 0.2s;
    }
}

a.active-link {
    color: lighten($primary-color, 10%) !important;

    svg {
        color: lighten($primary-color, 10%) !important;
    }
}

// form assets
form input[type='submit'] {
    background-color: $primary-color;
    color: $bg-color;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;

    &:hover,
    &:focus {
        background-color: lighten($primary-color, 5%);
        color: $bg-color-light;
        box-shadow: 1px 1px 3px lightslategrey;
    }
}

// form *:required {
//   box-shadow: 0 0 1px $primary-color;
// }

// form input[type="text"],
// form input[type="number"],
// form textarea,
// form select,
// form input[type="password"],
// form input[type="email"] {
//   background-color: $bg-color;
//   border-radius: 5px;
//   font-size: 1rem;
//   padding: 0.5rem;
//   margin: 0.2rem 0 0.1rem 0;
//   border: 1px solid transparent;
//   transition: all 0.2s;
//
//   &:focus {
//     background-color: white;
//     border: 1px solid $primary-color;
//   }
// }

form textarea {
    width: 100%;
    height: 5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    border: 1px solid #b6b6b6;
    resize: none;
}

form div.form-row {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    width: 100%;
}

.background-decoration-svg {
    position: fixed;
    z-index: -1;
    right: 100px;
    bottom: 0;
}

.background-main-svg {
    z-index: -1;
    margin-right: -100px;
}

.only-container {
    margin-right: 15rem;
}

/** gestion des tableaux */

// correspond aux en têtes permettant de sort
.sorting {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    transition: all 0.2s;

    &:hover {
        transform: scaleX(101%);
        color: $valid-color;

        svg {
            color: $valid-color;
        }
    }
}

.rounded {
    $radius: 15px;

    thead {
        tr {
            th:first-child {
                border-top-left-radius: $radius;
            }

            th:last-child {
                border-top-right-radius: $radius;
            }
        }
    }
}

.table > th,
.table > td {
    text-indent: 10px;
    border: 0px solid transparent;
}

.table--classic {
    box-shadow: 3px 3px 4px slategray;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    background-color: white;
    border-radius: 15px;

    th:last-child,
    td:last-child {
        width: 40%;
    }

    thead {
        tr {
            color: $primary-color;
            text-align: left;

            th {
                padding: 10px 12px;
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 10px 12px;
                font-size: 1rem;
                animation: fadeIn 0.2s;

                color: $secondary-color;
            }
        }

        tr.deleted-rows {
            td {
                color: $primary-color--dark;
            }
        }

        tr.deleted-rows:nth-child(odd) {
            background-color: #efefef;
        }

        tr.deleted-rows:nth-child(even) {
            background-color: #e0dfdf;
        }

        tr:nth-child(odd) {
            background-color: #efefef;
        }

        tr:nth-child(even) {
            background-color: white;
        }
    }

    &.table-legende {
        box-shadow: none;
    }
}

.table-action-button {
    text-decoration: none;
    margin: 0 5px;
    display: inline-block;
    width: 80px;
    text-align: center;
    color: white;
    cursor: pointer;
    padding: 3px 7px;
    font-size: 0.9rem;
    background-color: $valid-color;
    border-radius: 5px;
    transition: 0.2s;
    box-shadow: 1px 1px 2px slategray;

    &:hover {
        box-shadow: 2px 2px 3px slategray;
        background-color: $valid-color;
    }
}

.table-action-button:first-of-type {
    background-color: $secondary-color;
}

.table-action-button:nth-of-type(2) {
    background-color: $primary-color--dark;
}

.table-action-button:nth-of-type(3) {
    background-color: $primary-color;
}
