@import '../../../index.scss';

.pins-container {
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    background-color: #f3f5f9;
    border-radius: 5px;
    padding: 0.5rem;
    flex-wrap: wrap;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 10px;
        font-size: 0.95rem;
        color: $secondary-color--light;

        svg {
            color: $primary-color;
            transition: all 0.3s;
            font-size: 1.6rem;
            margin-right: 5px;
        }

        &:hover,
        &:focus {
            color: $valid-color;

            svg {
                color: $valid-color;
            }
        }
    }

    div.pin-container {
        height: 1.33em;
        max-width: 18ch;
        margin: 5px;
        overflow: hidden;
        background-color: white;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        transition: all 0.3s;
        border: solid 1px transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        &:hover,
        &:focus {
            border: solid 1px $primary-color;
        }

        div.text-container-pin {
            margin: 0;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 15ch;
        }

        svg {
            color: $secondary-color;
            font-size: 1.2rem;
            margin-left: 5px;

            &:hover,
            &:focus {
                color: $primary-color--dark;
            }
        }
    }
}
