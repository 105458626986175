.col-container {
    margin: 0.8rem;
    align-self: flex-start;
    span {
        text-align: left;
        align-self: flex-start;
    }
    ul {
        margin-top: 0.2rem;
        padding-left: 0;
        color: #444;
        text-indent: 0;
        list-style: none;
        pointer-events: none;
        li {
            display: flex;
            text-indent: 0.8rem;
            font-weight: lighter;
            justify-content: space-between;
        }

        b {
            margin-left: 0.5rem;
            color: black;
            font-weight: bolder;
        }
    }
}
