@keyframes onlyFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.icon-button {
    &:hover {
        color: 'red';
    }
}

.activities-right-panel {
    width: 90%;

    fieldset {
        margin-bottom: 0.5rem;
        animation: onlyFadeIn 0.2s;
    }

    .activities-accordion-container {
        width: 100%;
        // height: 73vh;

        fieldset {
            margin-bottom: 0;
        }

        .add-activity--button {
            border-radius: 3px;
            text-align: left;
            font-weight: 600;
            padding: 3px 4px;
            animation: onlyFadeIn 0.2s;

            svg {
                margin-right: 0.3rem;
            }
        }

        .add-conge {
            color: rgb(43, 105, 85);
        }

        .details-pin {
            border-left: 3px solid;
            border-radius: 3px;
            animation: onlyFadeIn 0.2s;
            padding: 4px 0;
            width: 98%;
            margin: 1px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            transition: all 0.2s;

            b {
                svg {
                    margin: 0 0.5rem;
                }
            }

            span.flex-container {
                justify-content: space-between;
                padding: 0.4rem;
                align-content: flex-start;

                .column-container {
                    align-self: flex-start;
                }

                span {
                    padding: 0;
                }

                .title-container {
                    width: 15rem;
                    word-wrap: anywhere;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    span {
                        font-size: 0.9rem;
                        font-weight: light;
                    }
                }
            }

            &:hover {
                filter: brightness(140%);
                cursor: pointer;
                box-shadow: 2px 2px 1px grey;
                border-left: 6px solid;
            }
        }
    }
}
