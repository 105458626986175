@import '../../index.scss';

#portal {
    padding: 0 2rem;
    transition: all 0.4s;
    h2 {
        font-weight: 600;
        font-size: 1.2rem;
        border-bottom: 2px solid $primary-color;
        width: 7rem;
        color: $secondary-color;
    }
    .portal-links-container {
        gap: 1rem;
        flex-wrap: wrap;
        // max-width: 90%;
        transition: all 0.4s;
        width: 100%;
    }
    .menu-modal {
        height: 0px;
        transition: 0.5s;
    }

    .admin-portal-container.menu-modal-displayed {
        width: 100%;
        .portal-links-container {
            flex-shrink: 1;
            transition: all 0.4s;
        }
        .menu-modal {
            padding: 1rem 0;
            margin: 0;
            height: 100%;
            width: 100%;
            // max-width: 80%;
            .sub-menu-container {
                transition: all 1s;
                border-radius: 15px;
                justify-content: center;
                flex-wrap: wrap;
                .button-component-container {
                    animation: popIn 0.5s;
                }
            }
        }
    }
}

@keyframes popIn {
    0% {
        font-size: 0.1rem;
        opacity: 0.1;
        transform: translateX(-600px);
    }
    100% {
        font-size: 1.3rem;
        opacity: 1;
        transform: translateX(0);
    }
}
