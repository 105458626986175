.conge-container.neo-card {
    ul.conge-list {
        width: 32em;
        max-height: 60vh;
        overflow-y: scroll;
        list-style: none;
        font-size: 1.05rem;
        .dot-type {
            border-radius: 100%;
            background-color: currentColor;
            width: 1rem;
            height: 1rem;
            flex-wrap: nowrap;
            flex-shrink: 0;
        }
        li {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            padding: 5px 0;
            color: rgb(119, 118, 118);
            border-bottom: 1px solid rgb(199, 199, 199);
            .validation-part {
                opacity: 0;
                padding: 1px;
                display: flex;
                transition: all 0.2s;
                pointer-events: none;
                width: 4rem;
                padding-right: 5px;
            }
            .validate {
                display: block;
                padding: 2px 5px;
                cursor: pointer;
                color: white;
                width: 1.6rem;
                font-size: 1.1rem;
                transition: all 0.2s;
            }
            .validate-check {
                background-color: #2b6955;
            }
            .validate-uncheck {
                background-color: #731100;
            }
            .validate:hover {
                filter: brightness(180%);
            }
        }
        li:hover {
            cursor: default;
            color: black;
            .validation-part {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}
